
/* ********************************slide show component *****************************/

/* @media only screen and (min-width: 375px) and (max-width: 969px) {  
    body { 
      background: green;
      font-size: 16px;
    }
  } */

   /* css for view button  ("view my story") on slides */
@media only screen and (max-width: 520px) { /* for mobile view */
    .view_button {
        margin-top : -76%;
    }
}

@media only screen and (min-width: 520px) { /* for screen size > mobile view(520px)*/
    .view_button {
        margin-top: -16%;
        margin-left: 40%;
    }
}

/* styling of slide images */
.imageStyling {
    height : 100%;
    width : 100%;
}

.hide_button{
    visibility: hidden;
}
/* ************************************************************************************ */


/* ******************************** sec-2 :-- team *************************************** */

/* for the main div part */
/* .row{
    display: flex;
} */
/* for each member or column on full screen size or greater than 520 px */
.column {
    /* float: left; */
    /* width : 80%; */
    margin : 10px 50px ;
  }
  
  /* css for the screen size > mobile view (520) */
.team_members{
    display: flex;
    justify-content: center;
    align-items: center;
}

/* so that only single team member takes the whole page on mobile view */
/* @media only screen and (max-width: 520px) {
    .column {
        width: 80% !important;
        
    }
} */
/* ************************************************************************************* */


/* ************************************** sec-1 **************************************** */
#title_ForSchool{
    margin-top : 60px;
    display : flex;
    justify-content: center;
    align-items : center;
    margin-bottom: -45px;
}
/* **************************************************************************************** */
