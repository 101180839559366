/* ************************************ header component ********************************* */

/* css to be added when the data scroll position is not at 0 */
html:not([data-scroll='0']) .navbar {
    background-color: white;
    position : fixed;
    padding: 0;
    top : 0;
}
/* style={{ color: "#fff !important" }} */
.logo_image{
    margin-right: -45px;
    padding-top : 15px;
}

@media only screen and (max-width: 520px) {
    .logo_image{
        margin-bottom: 10px;
        margin-left: -160px;
        /*height : 100px;*/
        /*width : 40px;*/
        padding-left: 40px;
    }
}

  /* so that the slide show not cover under the navbar */
.slide-container{
    margin-top : 70px;
}

.is-active{
    /*background-color: whitesmoke;*/
    color:red ;
    font-weight:bold;
}


/*@media only screen and (max-width: 520px) {*/
/*    .slide-container{*/
/*        margin-top : 80px;*/
/*    }*/
/*}*/
/* **************************************************************************************** */



/* ************************************* about ********************************** */

.section_class{
    background-size: 100% 40%;
    background-position: center bottom 53%;
}

/* ************************************************************************************** */



/* ******************************* crousel look page ******************************* */

.handleSpace{
    margin-top : -10px !important;
    margin-bottom : 40px;
}

/* ************************************************************************************** */



/* ******************************* contact us page ******************************* */

/* get_in_touch page */
.mapStyle {
    position: absolute;
    width: 80%;
    bottom: 10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    color: #000;
    text-align: center;
}


/* ************************************************************************************** */


/* ******************************* single child page ******************************* */




/* ************************************************************************************** */
